a,
a:link,
a:visited {
  color: currentColor
}

.x_1_work_place .x_1_work_place a,
.x_1_work_place a:link,
.x_1_work_place a:visited,
.x_1_work_place a:hover,
.pr_link,
.pr_link a,
.pr_link a:link,
.pr_link a:visited,
.pr_link a:hover,
.pr_info {
    color: currentColor;
    border-color: currentColor !important;
}
/*MISHA DEFAULTS*/
/*MISHA DEFAULTS*/
/*MISHA DEFAULTS*/
/*MISHA DEFAULTS*/
/*MISHA DEFAULTS*/
/*MISHA DEFAULTS*/
hr {
  border-color: currentColor;
}
._rel {
  position: relative;
}

._f {
  display: flex;
  gap: 0.8em;
}

._ftop {
  display: flex;
  gap: 0.8em;
  align-items: flex-start;
}

._fc {
  display: flex;
  gap: 0.8em;
  justify-content: center;
}

._fr {
  display: flex;
  justify-content: flex-end;
  /* align-items: flex-end; */
}

._fend {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

._fl {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

._fspace {
  display: flex;
  justify-content: space-between;
}

._fm {
  display: flex;
  gap: 0.8em;
  align-items: center;
}

._fcc {
  display: flex;
  gap: 0.8em;
  justify-content: center;
  align-items: center;
}

._fv {
  display: flex;
  gap: 0.8em;
  flex-direction: column;
}

._fh {
  display: flex;
  gap: 0.8em;
  flex-direction: row;
}

._f100 {
  display: flex;
  gap: 0.8em;
  width: 100%;
  flex: 1 1 100%;
}

._f50 {
  display: flex;
  gap: 0.8em;
  width: 50%;
  flex: 1 0 50%;

}

._fgap0 {
  display: flex;
  gap: 0em;
}

._fgap01 {
  display: flex;
  gap: 0.1em;
}

._fgap02 {
  display: flex;
  gap: 0.2em;
}

._fgap04 {
  display: flex;
  gap: 0.4em;
}

._fgap02 {
  display: flex;
  gap: 0.2em;
}

._fgap1 {
  display: flex;
  gap: 1em;
}

._fgap2 {
  display: flex;
  gap: 2em;
}

._fgap4 {
  display: flex;
  gap: 4em;
}

._fgrow {
  display: flex;
  flex-grow: 1;
}

._fnogrow {
  display: flex;
  flex-grow: 0;
}

._fstretch {
  display: flex;
  align-self: stretch;
  justify-self: stretch;
  justify-content: stretch;
  align-content: stretch;
}

._fwrap {
  display: flex;
  flex-wrap: wrap;
}

._fnowrap {
  display: flex;
  flex-wrap: nowrap;
}

._tnowrap {
  white-space: nowrap;
}

._t100 {
  width: 100%;
}

._tl {
  text-align: left;
}

._tr {
  text-align: right;
}

._tc {
  text-align: center;
}

._click {
  cursor: pointer;
}

._fshrink {
  display: flex;
  flex-shrink: 1;
}

._fnoshrink {
  display: flex;
  flex-shrink: 0;
}

._fright {
  display: flex;
  align-self: flex-end;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
}

._t08 {
  font-size: 0.8em;
}

._t12 {
  font-size: 1.2em;
}

._o08 {
  opacity: .8;
}

._o06 {
  opacity: .6;
}

._o04 {
  opacity: .4;
}

._o02 {
  opacity: .2;
}

.tnowrap {
  white-space: nowrap;
}

.x_tap {
  z-index: 2;
  cursor: pointer;
  position: absolute;
  inset: 0%;
}

/*MISHA DEFAULTS*/
/*MISHA DEFAULTS*/
/*MISHA DEFAULTS*/
/*MISHA DEFAULTS*/
/*MISHA DEFAULTS*/
/*MISHA DEFAULTS*/